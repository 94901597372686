import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { LinearProgress } from '@mui/material'
import { useMaterialsData } from '../../lib/materials_hook'
import { getCartItems, mapProductsToFetch } from '../../lib/cartitems_hook'
import { CartItemCalculated } from '../../types'
import { useProductsByIdData } from '../../lib/products_by_ids_hooks'
import { calculateCart } from '../../utils/CartUtils'
import DoOrderWithData from './DoOrderWithData'
import { getCart, getSeenPrices } from '../../lib/cart_hook'
import { navigate } from 'gatsby'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { getHash } from '../../utils/Crypto'

let run = 1
const DoOrder = (props: any) => {
  const [reload, setReload] = React.useState(false)

  const handleClick = () => {
    trackEvent({
      category: 'checkout',
      action: 'button',
      label: 'retry_order',
    })

    run += 1
    setReload(!reload)
  }

  const handleClickCart = () => {
    navigate('/warenkorb')
  }

  const materialsData = useMaterialsData()
  const cartItems = getCartItems()
  const cart = getCart()
  const prices = getSeenPrices()

  let cartItemsToDisplay: CartItemCalculated[] = []
  let cartItemsToRemove: CartItemCalculated[] = []

  const p = useProductsByIdData(mapProductsToFetch(cartItems))

  let sumMaterialGross = -1
  let sumShipping = -1
  const sumBonus = prices.bonus

  if (materialsData.length >= 0 && typeof p !== 'undefined') {
    const calculatedData: any = calculateCart(cartItems, materialsData, p)
    sumMaterialGross = calculatedData.sumMaterialGross
    cartItemsToDisplay = calculatedData.cartItemsToDisplay
    cartItemsToRemove = calculatedData.cartItemsToRemove
    sumShipping = calculatedData.sumShipping
  }

  let sumGross = sumMaterialGross + sumShipping - sumBonus

  if (sumGross <= 0) {
    sumGross = 0
  }

  const loadingData =
    cartItems.length == 0 ||
    cartItemsToDisplay.length == 0 ||
    cartItemsToDisplay.length + cartItemsToRemove.length != cartItems.length ||
    materialsData.length == 0 ||
    typeof p == 'undefined' ||
    sumMaterialGross == -1 ||
    sumBonus == -1 ||
    sumShipping == -1

  // console.log('DoOrder(' + loadingData + ')')

  if (loadingData) {
    return (
      <React.Fragment>
        <Typography gutterBottom variant="h6">
          Vorbereitung...
        </Typography>

        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  } else {
    const cartData = {
      cart: cart,
      newsletter: props.newsletter,
      cartItems: cartItemsToDisplay,
      sumShipping: sumShipping,
      sumBonus: sumBonus,
      sumMaterialGross: sumMaterialGross,
      sumGross: sumGross,
      run: run,
    }

    const hash = getHash(cartData)

    return (
      <DoOrderWithData
        cart={cartData}
        handleClick={handleClick}
        handleClickCart={handleClickCart}
        key={hash}
        orderHash={hash}
      />
    )
  }
}

export default DoOrder

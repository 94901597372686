import { fetchPaymentData } from './payment_api'
import { useState, useEffect } from 'react'
import { getHash } from '../../utils/Crypto'

export interface PaymentOptions {
  products: any
  voucherCode?: string
  country?: string
  deviceType: string
  hasInvoiceAddress: boolean
  mail?: string
}

export const usePaymentData = (options: PaymentOptions) => {
  const [paymentData, setPaymentData] = useState<any | undefined>(undefined)

  const signature = getHash(options)

  useEffect(() => {
    fetchPaymentData(options).then(response => {
      if (response.error > 0) {
      } else {
        setPaymentData(response.data)
      }
    })
  }, [signature])

  return paymentData
}
